import React from "react"
import {Container, Row, Col} from 'react-bootstrap';

import image from '../images/under-construction.svg';
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

export default() => {
    return (
        <section className='hero'>
            <Container>
                <Row>
                    <Col md={5}>
                        <h1>
                            We're working on something cool.
                            <span className='highlight'>
                                Stay close.
                            </span>
                        </h1>
                    </Col>

                    <Col md={7}></Col>
                    <img src={
                            `${image}`
                        }
                        className='img'/>
                </Row>
                <Row className='footer'>
                    <Col md={5}>
                        <h3>webdanescu.com</h3>
                        <h4>WEB DEVELOPMENT</h4>
                    </Col>
                </Row>
            </Container>
        </section>

    )
}
